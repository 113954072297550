.custom-plot-container {
  overflow-y: auto;
  height: 700px;
  width: 100%;
}

.custom-plot {
  width: 100%; /* This will take the full width of the container */
  /* The height will be set dynamically in the component */
}
